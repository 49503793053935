@use '../../../../styles/variables.scss' as *;

.actionButtons {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 $xl;
    gap: $normal;
}

.learnButton {
    background-color: $Turquoise;
    color: $White;
    max-width: 400px;
    margin: 0;

}

.skipButton {
    background-color: transparent;
    color: $Eggplant;
    max-width: 400px;
    margin-top: 0;
}