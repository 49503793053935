@use 'src/styles/variables' as *;

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $normal;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    h2 {
        font-weight: bold;
        margin: 0;
    }
}

.addFriend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $sm;
    width: auto;
    margin-top: $normal;
    background-color: $Eggplant;
}

.searchBox {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.countryList {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 250px;
    overflow-y: auto;
}

.countryItem {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}

.selected {
    background-color: #e0e0e0;
}

.flag {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}