@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;

.answers {
    overflow: hidden;
}

.slideContainer {
    margin-top: 20px;

    :global(.slick-slide) {
        padding: 0 10px; // Add some spacing between slides
    }

    :global(.slick-list) {
        overflow: visible; // This allows the border radius to be visible
    }
}

