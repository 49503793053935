@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;

.footerIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: $normal 0;
    background-color: white;
}

.iconDiv {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
}


.icon {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    aspect-ratio: 1/1;
    min-width: 25px; // Prevents shrinking
    min-height: 25px; // Prevents shrinking
    flex-shrink: 0;

    &.zoom {
        animation: zoom 0.6s ease-in-out infinite alternate;
    }
}

.label {
    font-size: 11px;
    text-align: center;
}