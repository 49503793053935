@use './variables.scss' as *;


body.dark {
    background-color: $Dark2;

    main {
        background-color: $Black;
    }

    * {
        color: $White;
    }

    input {
        border: solid 1px $White;
        margin: 5px 0;
        transition: 500ms;
        outline: solid 0px $Turquoise;
        background-color: $Dark4;

        &:focus-visible {
            border: solid 1px $Turquoise;
            outline: none;
            box-shadow: 0 0 3px $Turquoise;
        }
    }


    .container {
        background-color: $Dark2;
    }

    .word-list-fullmap {

        .range-box {

            .range-label {
                color: $White;
            }

            .progress-bar {

                &.uncertain {
                    background-color: $Dark4;
                }
            }
        }
    }

    .subscription-comparison {

        .container {

            .slider-container {

                .recycler-view {

                    .recycler-item {
                        background-color: $Dark4;
                        border: $Dark4;
                    }
                }
            }

            .recycler-dots canvas {
                background-color: $Dark4;

                &.active {
                    background-color: $Turquoise;
                }
            }

            .table {
                background-color: $Dark5;

                .row {

                    &.heading {
                        background-color: $Dark5;
                    }

                    .basic,
                    .pro {
                        background-color: $Black3;
                    }
                }
            }
        }
    }

    .earn-hearts {

        .item-description {

            * {
                color: $White;
            }
        }

        .container {
            background-color: $Dark2;
        }
    }

    .video-guides {

        * {
            color: $White;
        }

        .carousel-slide {
            background-color: $Dark2;
        }
    }
}