@use '../../../../styles/variables' as *;

.divider {
    margin: 0.5rem 0;
    width: 100%;
    border: 1px solid $Light2;

    &.thick {
        border-width: 2px;
    }

    &.noMargin {
        margin: 0;
    }
}