@use '../../../../styles/variables.scss' as *;

.mainSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $normal;
    justify-content: center;
    padding: 0 $lg $lg;
}

.lottieContainer {
    width: 100%;
    background-color: white;
    border-radius: $DefaultBorderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lottie {
    height: 250px;
    width: 200px;
}

.translateWord {
    color: $Turquoise;
}

h1,
h2 {
    margin: 0;
}