@use '/src/styles/variables.scss' as *;

.tagsView {
    width: 100%;
    padding: $normal $lg;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $sm;
}

.square {
    width: 100%;
    padding: $sm 0;
    border-radius: 2px;
    background-color: $Light2;
    cursor: pointer;
    font-size: 14px;
    min-height: 30px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.known {
        background-color: $Turquoise;
        color: $White;
    }

    &.shouldLearn {
        background-color: $Violet;
        color: $White;
    }
}

@media (min-width: 600px) {
    .tagsView {
        grid-template-columns: repeat(5, 1fr);
    }

}