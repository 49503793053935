@use '../../../../styles/variables' as *;

.wordListPageHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $normal $lg;

    h1 {
        flex: 1;
        text-align: center;
        margin: 0;
        font-size: 1.4rem;
    }

    h1:first-letter {
        text-transform: none;
    }
}

.backIcon {
    cursor: pointer;
    width: 40px;
    height: 40px;
}


.menu {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: black;
    background-color: $Light2;
    border-radius: 50%;
    padding: 6px;
}

.doneButton {
    margin: 0;
    padding: 7px;
    max-width: 70px;
}