@use '/src/styles/variables.scss' as *;
@use '/src/styles/animation' as *;

.componentModal {

    position: fixed;
    max-width: $max-width;
    margin: 0 auto;
    inset: 0;
    width: 100%;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $Light1;
    overflow-y: auto;

    opacity: 1;
    transition: opacity 0.3s ease-out;

    &.closing {
        opacity: 0;
    }

    &.slideRight {
        animation: slideRight 0.3s ease-out;
    }

    &.slideLeft {
        animation: slideLeft 0.3s ease-out;
    }

    &.slideUp {
        animation: slideUp 0.4s ease-in-out;
    }

    &.slideDown {
        animation: slideDown 0.4s ease-in-out;
    }

    &.rotateRight {
        animation: rotateRight 0.5s ease-out;
    }

    &.rotateLeft {
        animation: rotateLeft 0.5s ease-out;
    }

    &.rotateIn {
        animation: rotateIn 0.5s ease-out;
    }

    &.rotateOut {
        animation: rotateOut 0.5s ease-out;
    }
}


.modalContainer {
    width: 100%;
}