@use '/src/styles/variables' as *;

.coverImage {
    width: 100%;
    height: 30vh;
    object-fit: cover;
    min-height: 200px;
}

// @media screen and (min-width: 1024px) {

//     .coverImage {
//         height: 30vh;
//     }
// }