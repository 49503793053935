@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;

.secondWizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.questionText {
    margin: $lg 0;
    font-size: 1.8rem;

}

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $normal;
    width: 100%;
}

.optionLetter {
    font-weight: 600;
    color: $Turquoise;
}

.translateText {
    font-size: 1.1rem;
}

.option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $normal;
    background-color: $Light2;
    padding: $normal;
    border-radius: $sm;
    cursor: pointer;
    width: 100%;
    max-width: 300px;

    &.correct {
        animation: pulse 0.5s ease-in-out;
        background-color: $BrandGreen !important;
    }

    &.wrong {
        animation: wrongAnswer 0.5s ease-in-out;
        background-color: $Red;
        animation-fill-mode: forwards;
    }
}

.continueButton {
    max-width: 260px;
    margin-top: $lg;
}