@use 'src/styles/variables' as *;

.imageSection {
    position: relative;
    width: 100%;
}

.questionImage {
    width: 100%;
    height: 250px; // Fixed height
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: zoomInOut 20s ease-in-out infinite;

}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: black;
    background-color: $Light2;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    img {
        width: 20px;
        height: 20px;
    }
}