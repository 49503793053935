@use '../../../../styles/variables' as *;

.section {
    width: 100%;
    padding: 0 $normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $normal;
    cursor: pointer;
}


.row {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: $normal;
    width: 100%;
}

.text {
    font-weight: bold;
    font-size: 14px;
}

.icon {
    width: 2rem;
    height: auto;
}



.switch {
    display: flex;
    flex-direction: row;

    >div {
        padding: $xs $sm;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
        }

        span {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    >div:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    >div:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .notActive {
        background-color: $Light1;

        span {
            color: $Eggplant2;
        }
    }

    .activeGray {
        background-color: $Eggplant3;

        span {
            color: white;
        }
    }

    .active {
        background-color: $Turquoise;

        span {
            color: white;
        }
    }
}