@use '../../../../../styles/variables' as *;

.learnMoreButton {
    color: $Eggplant2;
    background-color: $Light2;
    margin: 0;
}

.nextWordButton {
    margin: 0;
}