@use '/src/styles/variables.scss' as *;

.chartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $sm;
}

.circleChart {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
        .backgroundRing {
            transition: all 0.3s ease;
        }

        .progressRing {
            transition: all 0.3s ease;
            stroke-linecap: round;
        }
    }

    &:hover {
        .progressRing {
            filter: brightness(0.95);
        }
    }
}

.title {
    font-size: 14px;
    font-weight: 500;
    color: $Eggplant;
    text-align: center;
    white-space: pre-line;
}