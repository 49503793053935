@use '../../../styles/variables' as *;

.mainLearn {
    width: 100%;
    overflow: hidden;
    padding: $normal 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $normal;
}

.mainContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}




.wordInfo {
    width: 100%;
    padding: $lg 0 $sm;
    transition: transform 0.3s ease;

    h1 {
        margin: 0;
        color: $Eggplant;
        font-size: 24px;
    }

    p {
        margin: 5px 0 0;
        font-size: 15px;
        color: $Eggplant;
    }

    &.playing {
        transform: scale(1.1);
    }

    h1,
    p {
        transition: transform 0.3s ease;
    }

    &.playing h1 {
        transform: scale(1.1);
    }

    &.playing p {
        transform: scale(1.1);
    }
}