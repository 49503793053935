@use '../../styles/variables' as *;

.lessons {
    display: flex;
    flex-direction: column;
    gap: $lg;
    padding: $normal;
}

.section {
    display: flex;
    flex-direction: column;
    gap: $lg;
    padding: $normal;
    border-radius: $normal;
    background-color: $White;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.title {
    font-size: $lg;
    font-weight: 700;
    color: $BrandGreen;
    align-self: flex-start;
    margin: 0;
}