@use '../../../../styles/variables' as *;

.headerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $normal;
}

.titleSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        cursor: pointer;
        width: 30px;
        height: 30px;

    }

    h1 {
        color: $Eggplant;
        font-size: 26px;
        font-weight: 700;
        margin: 0;
    }

}


.progressSection {
    display: flex;
    align-items: center;
    width: 70%;
    margin-top: $normal;
}