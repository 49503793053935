@use '/src/styles/variables.scss' as *;

.actionButtons {
    margin-top: $xl;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $normal;
}

.skip {
    background-color: $Light2;
    color: $Eggplant;
    font-weight: bold;
}

.next {
    font-weight: bold;
}

.done {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.tick {
    color: $Turquoise;
    font-size: 1.8rem;
    font-weight: bold;
}

.repeat {
    max-width: 100px;
    background-color: $Light2;
    color: $Eggplant;
    margin-top: $sm;
}