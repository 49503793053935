@use '../../../styles/variables' as *;

.progressHeader {
    width: 100%;
    background: var(--bg-image), linear-gradient(to right, $Violet3 35%, #4B156A 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.backGroundImage {
    width: 100%;
    max-height: 300px;
}

.content {
    width: 100%;
    //   position: absolute;
    top: 0;
    left: 0;
    padding: $normal;
    display: flex;
    flex-direction: column;
    gap: $lg;
}

.avatarSection {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $normal;
}

.avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.textContent {
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0;
        color: $White;
    }

    h3 {
        margin: 0;
        color: $White;
    }
}

.uncoverCount {
    margin: 0;
    color: $White;
    flex-grow: 1;
}

.dataSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $normal;
}

.dataItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $DefaultBorderRadius;
    padding: $sm;
    gap: $normal;
    background-color: #42135C;

    h3,
    h4 {
        margin: 0;
    }
}

.knewTitle {
    color: $Turquoise;
}

.learningTitle {
    color: $Violet2;
}

.toLearnTitle {
    color: $Violet;
}

.continueButton {
    background-color: $Turquoise;
    margin-top: 0;
}