@use 'src/styles/variables' as *;

.lottieContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin: 0;
        font-size: 1.4rem;
    }
}

.lottie {
    width: 50px;
    height: 50px;
}

.praise {
    font-size: 1.8rem;
    font-weight: 600;
    color: $Turquoise;
}