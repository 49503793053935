@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;

.section {
    width: 100%;
    padding: 0 $normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $normal;
    cursor: pointer;
}

.row {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: $normal;
    width: 100%;
}

.text {
    font-weight: bold;
    font-size: 14px;
}

.icon {
    width: 2rem;
    height: auto;
}



.arrow {
    width: 2.2rem;
    height: auto;
}

.rotating {
    animation: rotate 1s linear infinite;
}