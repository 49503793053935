@use '../../../styles/variables' as *;
@use '../../../styles/animation' as *;

.discoverPage {
    // Header - fixed height
    > :first-child {
        flex: 0 0 auto;
    }

    // Slider - take remaining space
    > :nth-child(2) {
        flex: 1;
        min-height: 0; // Important to allow shrinking
        overflow-y: auto; // Allow scrolling within slider
        overflow-x: hidden;
    }

    // Knowledge Map - fixed height
    > :last-child {
        height: 15%;
    }
}

@media (min-width: 600px) {
    .discoverPage {
        > :last-child {
            height: 23%;
        }
    }
}