@use '../../../styles/variables' as *;
@use '../../../styles/animation' as *;

.lessonPage {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    inset: 0;
    width: 100%;
    animation: slideRight 0.3s ease-out;
    overflow: hidden;
    background: $Light1;
}

.questionImage {
    width: 100%;
    height: 300px; // Fixed height
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: zoomInOut 20s ease-in-out infinite;

}

.questionContainer {
    //  z-index: 100;
    position: relative;
    margin-top: -30px;

    :global(.slick-slide) {
        padding: 0 10px; // Add some spacing between slides
    }

    :global(.slick-list) {
        overflow: visible; // This allows the border radius to be visible
    }
}

.lottieContainer {
    position: absolute;
    inset: 0;
    z-index: 100;
    width: 100%;
    border-radius: $DefaultBorderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
}


.lottie {
    width: 200px;
    height: 200px;
}