@use '/src/styles/variables.scss' as *;
@use '/src/styles/animation.scss' as *;

.flag {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    border-radius: 50%;

    &.zoom {
        //   animation: rotate 1s linear infinite;
        animation: zoom 0.6s ease-in-out infinite alternate;
    }
}

.translateText {
    font-size: 1.1rem;
    font-weight: 600;
    color: $Violet;

    &.dark {
        color: white;
    }

}