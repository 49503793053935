@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;

.search {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: $Light1;
    flex: 1;
}



.searchBox {
    margin-bottom: $lg;
    position: relative;
    padding: 0 $normal;
}

.searchInput {
    width: 100%;
    padding: 12px 16px;
    padding-right: 40px;
    border: none !important;
    font-size: 16px !important;
    background-color: white;
    text-align: center;

    &::placeholder {
        color: $Light2;
        text-align: center;
    }

    &:hover {

        &::placeholder {
            color: $Eggplant2;
        }

    }
}

.clearSearch {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.searchResults {
    display: flex;
    flex-direction: column;
}

.wordItem {
    padding: $sm $normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $lg;
}

.wordMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $sm;


    h3 {
        font-size: 15px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.addButton {
    background-color: $Grape2;
    padding: 5px 20px;
    border-radius: 8px;
    width: 100px;

}

.wordRank {
    color: $Eggplant2;
    font-size: 15px;
    font-weight: 700;

    &.learn {
        color: $Violet;
    }

    &.known {
        color: $Turquoise;
    }
}

.wordForms {
    color: $Eggplant3;
    font-size: 13px;
    margin: 0;
    text-align: left;
}