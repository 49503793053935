@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;

.thirdWizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.translateText {
    margin: $normal 0 0;
    font-size: 1.8rem;
    //   min-height: 24px;
}

.wordBank {
    display: flex;
    gap: $normal;
    margin: 0 0 $lg;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: white;
    width: 100%;
    padding: $normal;
}

.word {
    padding: $sm $normal;
    background: $Light1;
    border-radius: 4px;
    cursor: move;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &.used {
        opacity: 0.2;
        pointer-events: none;
    }
}

.dropZone {
    display: flex;
    gap: $normal;
    border: 1px solid $Violet;
    margin: $lg 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: white;
    width: 100%;
    padding: $normal;

    &.success {
        background-color: $BrandGreen;
        border-color: green;
    }
}

.box {
    width: 80px;
    height: 40px;
    background-color: $Light1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    cursor: pointer;

    &.punctuationBox {
        pointer-events: none;
        cursor: default;
        width: 40px; // Smaller width for punctuation
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &.incorrect {
        animation: wrongAnswer 0.5s ease-in-out;
        background-color: $Red;
        animation-fill-mode: forwards;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;

        &:hover {
            background-color: $Light1; // Prevent hover effect when disabled
        }
    }
}

.checkButton {
    max-width: 260px;
}