@use '/src/styles/variables' as *;

.compareItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $lg;
}

.description {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}


.image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.description {
    position: relative;

    &.collapsed {
        max-height: 120px; // Increased to show two full paragraphs
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px; // Increased height of gradient
            background: linear-gradient(transparent 0%,
                    rgba(255, 255, 255, 0.7) 40%,
                    white 100%);
        }
    }
}

.expandButton {
    position: absolute;
    bottom: -20px; // Moved lower
    left: 50%;
    transform: translateX(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    z-index: 1;

    &:hover {
        opacity: 0.8;
    }
}