.slick-list {
  margin: 0 -5px;
}

.slick-slide>div {
  padding: 0 5px;
}


.slick-track {
  display: flex !important;

  .slick-slide {
    height: inherit !important;

    >div {
      height: 100%;
    }
  }
}

// .slick-slide[aria-hidden="true"] * {
//   pointer-events: none;
//   visibility: hidden;
//   /* Optional: Hide visually as well */
// }