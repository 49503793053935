@use 'src/styles/variables' as *;

.firstWizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.questionText {
    margin: $lg 0 $normal;
    font-size: 1.8rem;

}

.questionImg {
    width: 130px;
    cursor: pointer;
}

.continueButton {
    max-width: 260px;
    margin-top: $lg;
}