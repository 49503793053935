@use '../../../../../styles/variables.scss' as *;

.slideItem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $sm;
    padding: $sm $normal;

    &>* {
        width: 100%;
    }
}

.volumeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        text-align: left;

        &.smallerText {
            font-size: 1.3rem;
        }
    }


}

.rank {
    display: flex;
    align-items: center;
    gap: $normal;

    span {
        color: $Light4;
        font-size: 16px;
    }
}

.word {
    display: flex;
    flex-direction: column;
}

.translate {
    color: $Violet;
    text-align: left;
    font-size: 16px;
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // font-size: clamp(12px, 2vw, 16px);

    &.smallText {
        font-size: 13px;
    }
}

.primaryMeaning {
    text-align: left;
    font-size: 16px;
    flex: 1;
    position: relative;
}

.learnMore {
    font-size: 16px;
    color: $Light4;
    margin-left: 8px;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0
}



@media (min-width: 600px) {
    .container {
        gap: $sm;
        padding: $sm $lg ;
    }
}