@use '../../../styles/variables' as *;

.practiceItem {
    display: flex;
    background-color: white;
    border-radius: $DefaultBorderRadius;

}

.practiceItemImage {
    width: 85px !important;
    height: 85px !important;
    min-width: 85px; // Prevent shrinking
    border-radius: $DefaultBorderRadius 0 0 $DefaultBorderRadius; // Optional: round left corners
}

.practiceItemContent {
    display: flex;
    flex-direction: row;
    gap: $lg;
    padding: $sm $normal;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .pro {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.practiceItemContentText {
    display: flex;
    flex-direction: column;
    gap: $sm;
    align-items: flex-start;

    p {
        text-align: left;
        color: $Eggplant3;
        margin: 0;
        font-size: 12px;
    }

    h2 {
        margin: 0;
    }
}

.first {
    background-color: $Violet;
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $DefaultBorderRadius 0 0 $DefaultBorderRadius;

    span {
        color: white;
        font-size: $lg;
        font-weight: bold;
        width: 85px;
    }
}

.practiceItemContentButton {
    display: flex;
    align-items: center;
    background-color: $Light2;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1; // Ensures perfect circle
    min-width: 35px; // Prevents shrinking
    min-height: 35px; // Prevents shrinking
    flex-shrink: 0; // Prevents flex container from squishing it

    img {
        width: 20px;
        height: 20px; // Make height explicit
        object-fit: contain; // Maintains aspect ratio
    }
}