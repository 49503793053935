@use "/src/styles//variables.scss" as *;

.proTips {
    width: 100%;
    padding: 0 0 $normal;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $normal;
    margin-bottom: $normal;

    img {
        width: 2.5rem;
        height: 2.5rem;
    }


    h1 {
        color: $Grape2;
        font-size: 18px;
        font-weight: bold;
        opacity: 0.7;
        margin: 0;
    }
}