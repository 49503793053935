@import 'src/styles/variables.scss';

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $normal;

    button {
        margin: 0;
    }
}

.done {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.tick {
    color: $Turquoise;
    font-size: 1.8rem;
    font-weight: bold;
}

.repeat {
    max-width: 100px;
    background-color: $Light2;
    color: $Eggplant;
    margin-top: $sm;
}