@use '/src/styles/variables.scss' as *;


.wellDone {
    width: 100%;
    position: fixed;
    inset: 0;
    overflow: hidden;
    max-width: $max-width;
    margin: 0 auto;
    padding: $lg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $normal;
    align-items: center;
}

.praise {
    font-size: 1.8rem;
    font-weight: bold;
}

.lottieContainer {
    width: 100%;
    border-radius: $DefaultBorderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lottie {
    height: 250px;
    width: 250px;
}