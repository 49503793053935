@keyframes slideRight {
    from {
        transform: translateX(100%);
        //   opacity: 0;
    }

    to {
        transform: translateX(0);
        //  opacity: 1;
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(-100%);
        // opacity: 0;
    }

    to {
        transform: translateX(0);
        // opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes rotateRight {
    from {
        transform: rotateY(-90deg);
        opacity: 0;
    }

    to {
        transform: rotateY(0);
        opacity: 1;
    }
}

@keyframes rotateLeft {
    from {
        transform: rotateY(90deg);
        opacity: 0;
    }

    to {
        transform: rotateY(0);
        opacity: 1;
    }
}

@keyframes rotateIn {
    from {
        transform: rotate(-180deg) scale(0);
    }

    to {
        transform: rotate(0) scale(1);
    }
}

@keyframes rotateOut {
    from {
        transform: rotate(0) scale(1);
    }

    to {
        transform: rotate(-180deg) scale(0);
    }
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.3);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes zoomInOut {
    0% {
        background-size: 100%;
    }

    50% {
        background-size: 110%;
    }

    100% {
        background-size: 100%;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translate(-50%, -45%);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    to {
        opacity: 0;
        transform: translate(-50%, -55%);
    }
}

@keyframes wrongAnswer {
    0% {
        transform: scale(1);
        background-color: #FA1E64;
    }

    50% {
        transform: scale(0.95);
        background-color: #FA1E64;
    }

    100% {
        transform: scale(1);
        background-color: transparent;
    }
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}