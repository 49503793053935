@use 'src/styles/variables' as *;

.answer {
    padding: $normal;
    display: flex;
    flex-direction: column;
    gap: $normal;
    background-color: white;
    border-radius: $DefaultBorderRadius;

    h1 {
        margin: 0;
    }
}

.number {
    width: 100%;
    color: white;
    background-color: $Violet;
    padding: $normal;

    &.done {
        background-color: $Turquoise;
    }
}

.translateText {
    margin: 0;
    color: $Violet;
    min-height: 24px;
}

.footer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $normal;
}

.peopleImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.peopleImage {
    max-height: 150px;
}