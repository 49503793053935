@use '../../../../styles/variables' as *;

.discoverPageHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $normal $lg;

    h1 {
        flex: 1;
        text-align: center;
        margin: 0;
        font-size: 1.4rem;
    }

    .iconGroup {
        display: flex;
        align-items: center;
        gap: $normal;
    }
}

.backIcon {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.menuIcon {
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.downIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

@media (min-width: 600px) {
    .discoverPageHeader {
        padding: $sm $lg;
    }
}