@use "/src/styles/variables.scss" as *;

.compare {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $lg;
    padding: $lg;
}


.title {
    color: $Grape2;
    align-self: center;
    font-size: 18px;
    font-weight: bold;
    opacity: 0.7;
}

.words {

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: $normal;
}

.word {
    padding: $normal ;
    cursor: pointer;
    font-weight: 600;
    border-radius: 0.5rem;
    font-size: 13px;

    &.green {
        background-color: $Turquoise;
        color: white;
    }

    &.gray {
        background-color: $Light2;
        color: $Eggplant;
    }
}