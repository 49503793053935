@use '../../../styles/variables' as *;

.myProgress {
    width: 100%;
    padding: $normal;
    background-color: $Light1;

    h2 {
        color: Black;
        font-weight: bold;
        margin-bottom: 5px;
    }
}

.progressContainer {
    padding: $normal $sm;
    background-color: white;
    border-radius: $DefaultBorderRadius;
}

@media (min-width: 480px) {
    .progressContainer {
        padding: $normal;
    }
}