.playButton {
    position: absolute;
    bottom: -25px;
    width: 50px;
    height: 50px;
    cursor: pointer;

    transition: transform 0.3s ease;

    &.playing {
        transform: scale(1.2);
    }
}