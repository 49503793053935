@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;

.lessonQuestion {
    width: 100%;
    height: 100%;
    border-radius: $DefaultBorderRadius;
    overflow: hidden;
}

.questionHeader {
    background-color: $Violet;
    padding: $normal;
    color: $White;

    &.done {
        background-color: $Turquoise;
    }
}

.questionContent {
    height: 100%;
    background-color: $White;
    padding: $lg $lg $normal;
    min-height: 200px;
}

.translateText {
    margin-top: 10px;
    min-height: 24px;
}