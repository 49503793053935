@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;

.wisdom {
    padding: $lg 0 0;
    background-color: white;
}

.wisdomContainer {
    position: relative;
    width: 100%;
    min-height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: zoomInOut 20s ease-in-out infinite;
    overflow: hidden;
}

.wisdomContent {
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
}

.wisdomDescription {
    margin: 0 0 $lg;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}