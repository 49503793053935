@use 'src/styles/variables' as *;


.progressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $normal;
}

.progressContainer {
    display: flex;
    justify-content: center;
    gap: 0.7rem;
}

.progressStep {
    width: 45px;
    height: 8px;
    background-color: $GrapeLight;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &.active {
        background-color: $Grape; // Purple color from the image
    }
}

.question {
    color: $Grape;
    margin: 0;
}