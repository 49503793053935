@use '/src/styles/variables.scss' as *;

.buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: $normal;

    button {
        padding: 6px 14px;
        max-width: auto;
        width: auto;
        font-size: 11px;
        margin: 0;
    }
}

.styledButton {
    padding: 0 $lg;
    background-color: $Light1;
    cursor: pointer;
    border-radius: $sm;
    font-size: 13px;
    max-width: 160px;

    &.knew {
        color: $Turquoise;
    }

    &.learn {
        color: $Violet;
    }
}