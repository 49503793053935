@use '../../../styles/variables' as *;

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $lg;
    padding: $normal;
    height: 65px;
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: $Light1;
    max-width: $max-width;

    &.progressBar {
        background-color: $Violet3;
    }

}

.headerTitle {
    align-self: center;
    flex-grow: 1;
    color: $Eggplant;
}

.menu {
    width: 40px;
    cursor: pointer;

}

.logo {
    width: 40px;
    height: auto;
    cursor: pointer;
}

.progressContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progressImage {
    height: 60px;
}