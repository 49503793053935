@use '../../../../styles//variables.scss' as *;

.progressChart {
    cursor: pointer;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $normal;

    >* {
        width: 100%;
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    h2 {
        font-weight: bold;
        margin: 0;
    }
}

.modalBody {
    text-align: left;
}