@use '/src/styles/variables' as *;


.importWordsForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: $normal $lg $lg;
    height: 100%;
    gap: $normal;

    h2,
    p {
        margin: 0;
    }

}

.textarea {
    width: 100%;
    height: 200px;
    padding: $normal;
    margin-bottom: $normal;
}