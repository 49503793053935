@use "/src/styles//variables.scss" as *;

.proTip {
  width: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: $normal;
  border-radius: $DefaultBorderRadius;
  background-color: white;
  padding: $normal 0;

  &.withImage {
    padding: $normal 0 80px;
  }
}

.header {
  width: 100%;
  padding: 0 $normal;
}

.title {
  font-weight: bold;
  color: $Violet3;
  font-size: 20px;
}

.description {
  font-size: 18px;
  font-style: italic;
  text-align: left;
  padding: 0 $normal;
}



.coverImage {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}


.example {
  width: 90%;
  font-weight: bold;
  text-align: left;
  color: $Eggplant;
  background-color: $Light1;
  border-radius: $DefaultBorderRadius;
  padding: 15px;


  &.withImage {
    position: absolute;
    bottom: -60px;
  }
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.translate {
  font-size: 16px;
  font-weight: 600;
  color: $Violet;
  text-align: left;
  margin-top: $normal;
}

.quote {
  width: 40px;
  height: 40px;
}

@media screen and (min-width: 1024px) {

  .coverImage {
    max-height: 50vh;
  }
}