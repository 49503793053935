@use '../../../../../styles/variables' as *;

.definition {
    font-size: 16px;
    font-weight: 600;
}

.example {
    font-size: 16px;
    font-style: italic;
}

.translate {
    font-size: 15px;
    font-weight: 600;
    color: $Violet;
}