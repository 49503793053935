@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;


.mainNavigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: $sm 0;
    width: 85%;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 1003;
    position: absolute;
    animation: slideLeft 0.4s ease-in-out;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1002;
    animation: fadeIn 0.3s ease-in-out;
}

.logo {
    width: auto;
    height: 3rem;
    margin-bottom: $sm;
}

.proButton {
    margin: 0;
    width: 85%;
    margin-bottom: $normal;
}

.version {
    font-size: 12px;
    color: $Eggplant;
    margin-top: $normal;
}