@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;


.finalWizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.translateText {
    margin: $normal 0 0;
    font-size: 1.8rem;

}

.textInput {
    max-width: 90%;
    min-height: 80px;
    margin: $normal 0 !important;
    background-color: white;

    &.wrong {
        animation: wrongAnswer 0.5s ease;
    }
}

.buttonSections {
    display: flex;
    gap: $lg;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 $normal;
    margin-top: $normal;

    button {
        max-width: 70%;
    }
}