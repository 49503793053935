@use '../../../../styles/variables' as *;

.textContentWrapper {
    width: 100%;
    position: absolute;
    top: calc(100% - 30px);
    left: 0;
    right: 0;
    z-index: 1;
    z-index: 1;
    padding: 0 $lg $lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $lg;
}

.textContent {
    display: flex;
    flex-direction: column;
    padding: $normal;
    background-color: white;
    gap: $normal;
    border-radius: $DefaultBorderRadius;
    width: 100%;
}

.textContentContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: $normal;
    border-radius: $DefaultBorderRadius;
}

.clareItems {
    display: flex;
    flex-direction: column;
    gap: $normal;
    padding: $normal $lg;
    border-radius: $DefaultBorderRadius;
    background-color: $Light1;
}



.clareItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: $normal;
}

.clareTitle {
    color: #8349A9;
    opacity: 0.59;
    margin: 0;
}

.clareDescription {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}

.rectangle {
    width: 0.7rem;
    height: 0.7rem;
    background: black;
    transform: rotate(45deg);
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: $normal;
}

.backButton {
    background-color: $Light2;
    color: $Eggplant2;
}