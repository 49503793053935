@use '../../../../styles/variables' as *;

.skipModal {
    display: flex;
    flex-direction: column;
    gap: $normal;
    align-items: center;
    justify-content: center;


    p {
        margin-top: 0;
    }

    h1 {
        font-weight: bold;
        color: $Turquoise;
    }

    h1:first-letter {
        text-transform: uppercase;
    }
}

.radioGroup {
    display: flex;
    flex-direction: column;
    gap: $sm;
    width: 100%;

    label {
        display: flex;
        align-items: center;
        gap: $normal;
        cursor: pointer;
        font-size: 16px;

        input {
            width: auto;
        }
    }
}

.radioInput {
    margin: 0;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border: 2px solid $Turquoise;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    background-color: white;
    padding: 10px;

    &:checked {
        background: $Turquoise;
        border: 2px solid white;
        outline: 2px solid $Turquoise;
        box-shadow: none;
    }
}