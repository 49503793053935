@use '../../../../styles/variables' as *;

.textContentWrapper {
    width: 100%;
    position: absolute;
    top: calc(100% - 30px);
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 $lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textContent {
    display: flex;
    flex-direction: column;
    padding: $normal ;
    background-color: white;
    gap: $normal;
    border-radius: $DefaultBorderRadius;
}

.type {
    color: $Turquoise;
}