@use '/src/styles/variables.scss' as *;

.video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $lg 0;
    background-color: white;
    gap: $normal;

    >div {
        width: 100%;
    }
}

.description {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0 $normal;
}