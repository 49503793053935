@use '../../../../styles/variables' as *;

.section {
    width: 100%;
    padding: 0 $normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $normal;
    cursor: pointer;
}

.video {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: $sm;
    border-radius: 8px;
    border: 1px solid $Light2;
    margin-top: $normal;
    padding: $sm;

    img {
        width: 2rem;
        height: auto;
    }
}