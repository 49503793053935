@use '../../../styles/variables' as *;

.discoverItem {
    display: flex;
    background-color: white;
    border-radius: $DefaultBorderRadius;
    cursor: pointer;

}

.discoverItemImage {
    width: 85px !important;
    height: 85px !important;
    min-width: 85px; // Prevent shrinking
    object-fit: cover; // Maintain aspect ratio and cover the space
    border-radius: $DefaultBorderRadius 0 0 $DefaultBorderRadius; // Optional: round left corners
}

.discoverItemContent {
    display: flex;
    flex-direction: row;
    gap: $lg;
    padding: $sm $normal;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .pro {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.discoverItemContentWrapper {
    width: 100%;
    position: relative;
    padding-top: 15px;
}

.discoverItemContentText {
    display: flex;
    flex-direction: column;
    gap: $sm;
    align-items: flex-start;
    width: 100%;

    p {
        text-align: left;
        color: $Eggplant3;
        margin: 0;
        font-size: 12px;
    }

    h3 {
        margin: 0;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.discoverItemContentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1; // Ensures perfect circle
    min-width: 35px; // Prevents shrinking
    min-height: 35px; // Prevents shrinking
    flex-shrink: 0; // Prevents flex container from squishing it

    img {
        width: 20px;
        height: 20px; // Make height explicit
        object-fit: contain; // Maintains aspect ratio
    }

    &.unComplete {
        background-color: $Light2;
    }

    &.complete {
        background-color: $Turquoise;
    }

}