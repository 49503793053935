@use '/src/styles/variables' as *;


.customListForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 $lg $lg;
    justify-content: space-between;
    height: 100%;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    gap: $normal;
}

.label {
    font-weight: bold;
    font-size: 16px;
}


.hidden {
    visibility: hidden;
}