@use '../../../styles/variables.scss' as *;
@use '../../../styles/animation.scss' as *;

.learnWizard {
    position: fixed;
    max-width: $max-width;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background-color: $Light1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>* {
        width: 100%;
    }

    &.slideRight {
        animation: slideRight 0.3s ease-out;
    }

}

.wizardContent {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    &.slideRight {
        animation: slideRight 0.3s ease-out;
    }

    &.slideLeft {
        animation: slideLeft 0.3s ease-out;
    }

    &.slideUp {
        animation: slideUp 0.4s ease-in-out;
    }

    &.rotateRight {
        animation: rotateRight 0.5s ease-out;
    }

    &.rotateLeft {
        animation: rotateLeft 0.5s ease-out;
    }

    &.rotateIn {
        animation: rotateIn 0.3s ease-out;

    }

    &.rotateOut {
        animation: rotateOut 0.3s ease-out;
    }
}