@use '/src/styles/variables' as *;

.senses {
    margin-top: -30px;
    margin-bottom: $normal;
    z-index: 1;

    // :global {
    //     .slick-track {
    //         display: flex !important;

    //         .slick-slide {
    //             height: inherit !important;

    //             >div {
    //                 height: 100%;
    //             }
    //         }
    //     }
    // }

}