@use '/src/styles/variables.scss' as *;

.emptyLearn {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $lg;
    gap: $normal;
    background-color: white;
    overflow: hidden;
    max-width: $max-width;
    margin: 0 auto;

    h1 {
        color: $Eggplant;
        margin-bottom: $normal;
    }

    img {
        max-width: 220px;
    }

    p {

        margin: 0;
    }

}