@import 'src//styles/variables.scss';


.sortModal {
    padding: 0 $normal;
    display: flex;
    flex-direction: column;
    gap: $lg;
}


.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;


    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    h2 {
        font-weight: bold;
        margin: 0;
    }
}

.modalBody {
    display: flex;
    flex-direction: column;
    gap: $normal;
}

.modalBodyItem {
    padding: $normal;
    border-radius: $DefaultBorderRadius;
    background-color: $Light2;
    cursor: pointer;

    &.active {
        background-color: $Violet;
    }
}