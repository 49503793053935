@use '/src/styles/variables.scss' as *;

.progressBar {
    display: flex;
    width: 100%;
}

.hrSegment {
    height: 100%;
}




.known {
    // background-color: $Turquoise;
    border-radius: $DefaultBorderRadius 0 0 $DefaultBorderRadius ;
}

.unknown {
    // background-color: $Violet;
}

.remain {
    //  background-color: $Light2;
    border-radius: 0 $DefaultBorderRadius $DefaultBorderRadius 0;
}