@use '/src/styles/variables.scss' as *;

.toastContainer {
    position: fixed;
    max-width: $max-width;
    margin: 0 auto;
    top: 0;
    z-index: 99999999;
    width: 100%;
}

.toast {
    width: 100%;
    margin-bottom: 8px;
    padding: 16px;
    border-radius: 4px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    animation: slideIn 0.3s ease-out;
}

.success {
    background-color: $Light2;
    color: $Grape !important;
}

.error {
    background-color: #f44336;
    color: white;
}

.info {
    background-color: #2196f3;
    color: white;
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}