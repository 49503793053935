@use 'src/styles/variables' as *;

.videoContainer {
    width: 100%;
    position: relative;
}

.video {
    width: 100%;
    max-height: 220px;
    object-fit: cover;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: black;
    background-color: $Light2;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100
    ;

    img {
        width: 20px;
        height: 20px;
    }
}