@use '/src/styles/variables.scss' as *;

.senseItem {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: $normal;
    border-radius: $DefaultBorderRadius;
    display: flex;
    flex-direction: column;
    gap: $normal;

    h1 {
        text-align: left;
    }
}

.volumeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.type {
    color: $Turquoise;
}

.definition {
    text-align: left;
    font-weight: bold;
}

.example {
    text-align: left;
    font-style: italic;
}

.audioPlayer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.translate {
    color: $Violet;
}

.notes {
    text-align: left;

    p {
        margin: 5px 0 0;
    }
}