@use '../../../../../styles/variables' as *;

.finishModal {
    font-size: 20px;
    font-weight: 600;
    color: $Violet;
    cursor: pointer;

}


.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
}