@use '/src/styles/variables.scss' as *;

.menuItems {
    background-color: $Light2;
    border-radius: $DefaultBorderRadius;
    padding: 4px 0;

    .menuItem {
        padding: 10px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: $normal;

        img {
            width: 20px;
            height: 20px;
        }
    }
}