@import 'src//styles/variables.scss';

.modalContent {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: $normal;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 $normal;

    button {
        margin-bottom: 0;
    }
}

.text {
    width: 100%;
    min-height: 200px;
    font-size: 1rem;
}