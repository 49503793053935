@use 'src/styles/variables' as *;
@use 'src/styles/animation' as *;

.fourthWizard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.speaker {
    width: 60px;
    cursor: pointer;
}

.questionText {
    // margin: $normal 0 $normal;
    font-size: 1.8rem;

}

.microphone {
    width: 65px;
    cursor: pointer;
    transition: transform 0.2s ease;

    &.recording {
        animation: pulse 1s infinite;
    }
}

.buttonSections {
    display: flex;
    gap: $lg;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 $normal;

    button {
        max-width: 70%;
        margin: 5px 0 5px;
    }
}

.spokenTextContainer {
    width: 100%;
    min-height: 80px;
    padding: $normal;
    margin: $sm 0;
    transition: all 0.3s ease;
    border: 1px solid $Violet;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    p {
        margin: 0;
        font-size: 18px;
        text-align: left;
        color: $Violet;

        &.placeholder {
            color: #cccccc;
            opacity: 0.7;
        }

        &.recording {
            color: $Violet;
        }

        &.correct {
            color: $Turquoise;
        }
    }

    &.wrong {
        animation: wrongAnswer 0.5s ease;
    }
}


.errorMessage {
    color: $Red;
    margin: 0 0 5px;
    text-align: center;
    font-size: 14px;
}