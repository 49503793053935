@use '/src/styles/variables.scss' as *;


.fullPage {
    width: 100%;
    position: fixed;
    inset: 0;
    overflow: hidden;
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loader {
    display: block;
    position: relative;
    width: 5rem;
    height: 5rem;
    margin: 1rem auto;

    &:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 0.8rem;
        box-sizing: border-box;
        border: 2rem solid;
        border-color: $Grape transparent $Violet transparent;
        animation: Loader 1.2s infinite;
    }

}

@keyframes Loader {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(1800deg);
    }
}