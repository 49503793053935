@use './variables.scss' as *;
@use './animation.scss' as *;

html {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
}

body {
    font-size: inherit;
    font-weight: inherit;
    text-align: center;
    background-color: #ddd;
    color: $Eggplant;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: $max-width;

    * {
        box-sizing: border-box;
        font-family: 'Poppins', Arial, sans-serif;
        color: $Eggplant;
        overflow-wrap: anywhere;
        scrollbar-width: thin;
        font-size: inherit;
        font-weight: inherit;
    }
}

#root {
    background-color: $Light1;
    box-shadow: #888 0 0 36px -6px;
    overflow: hidden;
}

h1 {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
}

h1:first-letter {
    text-transform: capitalize;
}

h2 {
    font-size: 1.1rem;
    font-weight: 600;
}

h3 {
    font-size: 1rem;
}

h4 {
    font-size: 0.9rem;
}

h5 {
    font-size: 0.8rem;
}

hr {
    margin: 0;
    background-color: $Light2;
    height: 1.5px;
    border: none;
    width: 100%;
}

label {
    font-weight: 400;
    font-size: 0.9rem;
    margin: 5px 0;
    display: block;
    text-align: left;
}

small {
    font-weight: 200;
    text-align: center;
    color: $Eggplant3;
}

input[type="text"] {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border-radius: 5px;
    border: solid 1px $Black;
    margin: 5px 0;
}

textarea {
    width: 100%;
    min-height: 15rem;
    font-size: 1.4rem;
    padding: 10px;
}

a {
    text-decoration: solid;
}

button,
.button {
    display: block;
    padding: 10px;
    margin: 10px 0;
    background-color: $Violet;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    width: 100%;
    vertical-align: middle;
    opacity: 1;

    * {
        color: white;
        display: inline-block;
        margin: 0;
    }

    &:hover:not(:disabled) {
        box-shadow: $Eggplant 0 0 5px;
        transition: 300ms;
    }

    &.turquoiseButton {
        background-color: $Turquoise;
        color: white;

        P {
            color: white;
        }
    }

    &.redButton {
        background-color: $Red;
        color: white;

        P {
            color: white;
        }
    }

    &.secondaryButton {
        background-color: $Light2;
        color: $Eggplant2;
    }

    &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }

    img {
        height: 20px;
        vertical-align: middle;
        margin: 0 5px;
    }
}

.fullPage {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    inset: 0;
    width: 100%;
    animation: slideRight 0.3s ease-out;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: $Light1;
}

.overFlowDots {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}