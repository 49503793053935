@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;

.playSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.icons {
    display: flex;
    gap: $sm;
}

.flag {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    border-radius: 50%;

    &.zoom {
        animation: zoom 0.6s ease-in-out infinite alternate;
    }
}