@use '/src/styles/variables' as *;

.lexiModal {
    padding: 0 $normal;
    display: flex;
    flex-direction: column;
    gap: $normal;
    align-items: center;
    justify-content: center;

    p {
        width: 100%;
        margin: 0;
        text-align: center;
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
    }

    img {
        max-width: 150px;
    }

}

.okButton {
    margin: 0;
}