@use '../../../../styles/variables.scss' as *;

.footerSection {
    margin-top: auto;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: $normal;
}

.backButton {
    background-color: $Light2;
    color: $Eggplant2;
}