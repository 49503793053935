@use '../../../../styles/variables' as *;

.section {
    width: 100%;
    padding: 0 $normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $normal;
    cursor: pointer;
}

.buttonGroups {
    padding: $sm $normal;
    background-color: $Light2;
    color: $Eggplant;
    border-radius: 6px;
}