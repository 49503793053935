@use '/src/styles/variables' as *;

.wordCard {
    width: 100%;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: $normal;
    max-height: 124.25px;
    position: relative;

}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // gap: $sm;
    padding: $sm $sm 9px 0;
}

.word {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    text-align: left;
}

.definition {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;

}

.coverImage {
    width: 100px !important;
    height: 124.25px !important;
    min-width: 110px; // Prevent shrinking
    object-fit: cover; // Maintain aspect ratio and cover the space
}

.removeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;


}