@use '/src/styles/variables' as *;

.pageHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $normal $lg;

    h1 {
        flex: 1;
        text-align: center;
        margin: 0;
        font-size: 1.4rem;
    }
}