@use '../../../styles/variables' as *;

.friends {
    width: 100%;
    padding: $normal;
    background-color: $Light1;

    h2 {
        color: Black;
        font-weight: bold;
        margin-bottom: 5px;
    }
}

.friendsContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid $Light2;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
}

.tab {
    width: 100%;
    cursor: pointer;
    padding: $sm $normal;
    color: $Eggplant3;
    background-color: $Light2;
    font-size: 1.1rem;
    font-weight: bold;
    padding: $normal;

    &.active {
        background-color: white;
        color: $Eggplant;
    }
}