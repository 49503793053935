@use '/src/styles/variables' as *;

.skipModal {
    display: flex;
    flex-direction: column;
    gap: $normal;
    align-items: center;
    justify-content: center;

    p {
        width: 100%;
        margin: 0;
        text-align: left;
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
    }

}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: $normal;

    button {
        margin: 0;
    }
}

.close {
    cursor: pointer;
    width: 20px;
    height: 20px;
}