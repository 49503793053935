@use '../../../styles/variables' as *;

.lessonsItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: $normal;
    background-color: white;
    border-radius: $DefaultBorderRadius;
    position: relative; // Add this for absolute positioning of the line
    cursor: pointer;
}

.lessonsItemContent {
    display: flex;
    flex-direction: row;
    gap: $normal;
    position: relative; // Add this for the line positioning

    // Add this for the connecting line
    &::before {
        content: '';
        position: absolute;
        top: 34.5px; // Half of the image height (75px/2)
        left: 34.5px; // Half of the image width (75px/2)
        width: 5px;
        height: calc(100% + 32px); // Adjust based on your gap
        background-color: $BrandGreen; // Or your preferred color
        z-index: 0;
    }
}

// Add this to handle the last item
.lessonsItem:last-child {
    .lessonsItemContent::before {
        display: none;
    }
}

.lessonsItemImage {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    position: relative; // Add this
    z-index: 1; // Add this to show image above line
    background-color: white; // Add this to hide the line behind
    border: 3px solid $BrandGreen;
}

.lessonsItemContentText {
    display: flex;
    flex-direction: column;
    gap: $sm;
    align-items: flex-start;
    justify-content: center;

    p {
        text-align: left;
        color: $Eggplant3;
        margin: 0;
        font-size: 12px;
    }

    h2 {
        margin: 0;
    }
}


.lessonsItemContentButton {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;
    background-color: $Light2;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1; // Ensures perfect circle
    min-width: 35px; // Prevents shrinking
    min-height: 35px; // Prevents shrinking
    flex-shrink: 0; // Prevents flex container from squishing it

    img {
        width: 20px;
        height: 20px; // Make height explicit
        object-fit: contain; // Maintains aspect ratio
    }

    &.firstLesson {
        background-color: $Violet;
    }

    &.completed {
        background-color: $Turquoise;
    }
}