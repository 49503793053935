@use '/src/styles/variables.scss' as *;
@use '/src/styles/animation.scss' as *;

.modalWrapper {
  &.open {
    .overlay {
      animation: fadeIn 0.2s forwards;
    }

    .modal {
      animation: slideIn 0.2s forwards;
    }
  }

  &:not(.open) {
    .overlay {
      animation: fadeOut 0.2s forwards;
    }

    .modal {
      animation: slideOut 0.2s forwards;
    }
  }
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998;
  opacity: 0;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: $lg $normal;
  border-radius: $DefaultBorderRadius;
  z-index: 999999;
  // width: 90%;
  max-width: 380px;
  opacity: 0;
}