//colors:
$Violet: #AE24F6;
$Violet2: #D692FA;
$Violet3: #6A1A94;
$Turquoise: #32BDD2;
$Turquoise2: #00C4FF;
$Turquoise3: #00A3D7;
$Eggplant: #3A1251;
$Eggplant2: #755985;
$Eggplant3: #9C88A8;
$Eggplant6: #D8D0DC;
$Eggplant7: #EFD3FD;
$Grape: #641C93;
$Grape2: #8349A9;
$GrapeLight: #C1A4D4;

$Light1: #EFF2F8;
$Light2: #D9DDED;
$Light3: #D3D3D3;
$Light4: #BAC3DA;
$Light5: #8F8F8F;
$Gray: #f8f8ff;
$Gray2: #dbd6d6;
$Gray3: #999999;
$White: #ffffff;
$Black: #121212;
$Black3: #0000003D;
$BrandGreen: #00A2B2;
$Dark1: #191919;
$Dark2: #232323;
$Dark4: #333333;
$Dark5: #4E4F54;
$Red: #FA1E64;

//size:
$normal: 0.8rem;
$sm: calc($normal / 2);
$xs: calc($normal / 4);
$lg: $normal * 2;
$xl: $normal * 3;
$xxl: $normal * 4;
$xxxl: $normal * 5;
$ul: $normal * 6;
$uul: $normal * 7;
$uuul: $normal * 8;


//radius:
$DefaultBorderRadius: $normal;
$SmBorderRadius: calc($DefaultBorderRadius / 2);

$max-width : 700px;