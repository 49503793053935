@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;

.iFrameModal {

    position: fixed;
    max-width: $max-width;
    margin: 0 auto;
    inset: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $normal 0;
    background-color: $Light1;
    animation: slideRight 0.4s ease-in-out;
}

.iframeHeader {
    width: 100%;
    padding: $normal;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.iframeHeader h1 {
    text-align: center;
    margin: 0;
    font-weight: bold;
    color: $Eggplant;
}

.closeButton {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.iframeContainer {
    width: 100%;
    flex: 1;
}

.iframeContainer iframe {
    width: 100%;
    height: 100%;
    border: none;
    //    overflow: hidden;
}