@use '../../../../../../styles/variables.scss' as *;

.buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $normal;
    margin-top: auto;

    button {
        margin: 0;
        padding: 10px 5px;
    }
}

.selectSection {
    display: grid;
    grid-template-columns: 60fr 40fr;

    &>* {
        height: 100%;
    }
}

.labelSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $sm;


    span {
        font-size: 13px;
    }
}

.styledButton {
    padding: 0 $lg;
    background-color: $Light1;
    cursor: pointer;
    border-radius: $sm;
    font-size: 13px;

    &.knew {
        color: $Turquoise;
    }

    &.learn {
        color: $Violet;
    }
}

.learnNowButton {
    background-color: $Grape;
    margin: 0;
    width: 1005;
}

@media (min-width: 600px) {
    .selectSection {
        grid-template-columns: 1fr 1fr;
    }

    .learnNowButton {
        width: 80%;
    }
}