@use '/src/styles/variables' as *;
@use '/src/styles/animation' as *;

.search {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    inset: 0;
    width: 100%;
    animation: slideRight 0.3s ease-out;
    overflow-x: hidden;
    overflow-y: auto;
    background: $Light1;
}



.searchBox {
    margin-bottom: $lg;
    position: relative;
    padding: 0 $normal;
}

.searchInput {
    width: 100%;
    padding: 12px 16px;
    padding-right: 40px;
    border: none !important;
    font-size: 16px !important;
    background-color: white;
    text-align: center;

    &::placeholder {
        color: $Light2;
        text-align: center;
    }

    &:hover {

        &::placeholder {
            color: $Eggplant2;
        }

    }
}

.clearSearch {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 30px;
    font-weight: 400;
    color: black;
    cursor: pointer;
    padding: 0 $sm;
    line-height: 0;
}

.recentSearches {
    .recentHeader {
        padding: 0 $lg;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $normal;

        p {
            font-size: 18px;
        }
    }
}

.clearButton {
    color: $Grape;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline;
}

.searchList {
    display: flex;
    flex-direction: column;
}

.searchItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: $normal;
    padding: 0 $lg;
    margin: $normal 0;
}

.searchTerm {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    flex: 1;
}

.removeButton {
    background: none;
    border: none;
    line-height: 0;
    font-size: 32px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}



.searchResults {
    display: flex;
    flex-direction: column;
}

.wordItem {
    padding: $sm $normal;
    border-radius: 8px;
    cursor: pointer;
}

.wordMain {
    display: flex;
    justify-content: space-between;
    align-items: center;


    h3 {
        font-size: 15px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
    }
}

.wordRank {
    color: $Grape;
    font-size: 15px;

    &.learn {
        color: $Grape2;
    }

    &.known {
        color: $Turquoise;
    }
}

.wordForms {
    color: $Eggplant3;
    font-size: 13px;
    margin: 0;
    text-align: left;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: $normal;
    border-radius: $DefaultBorderRadius;
    position: absolute;
    top: 50%;
    left: $normal;
    right: $normal;
    //  left: 50%;
    //  transform: translate(-50%, -50%);



    p {
        margin-bottom: 0;
        color: $Eggplant3;
    }
}

.emptyImage {
    width: 100px;
    height: 100px;
}