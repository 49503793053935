@use '../../../../styles/variables' as *;

.friendFooter {
    display: flex;
    justify-content: center;
}

.addFriend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $sm;
    width: auto;
    margin-top: $normal;
    background-color: $Eggplant;
}

.locationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $sm;
}

.textContainer {
    width: 100%;
}

.text {
    width: 100%;
    background-color: white;
    padding: $sm;
    font-size: 0.9rem;
}