@use '../../../../styles/variables.scss' as *;

.slider {
    position: relative;
    width: 100%;
    // min-height: 200px;
    // height: 100%;
    //  flex: 1;

    &>div {
        height: 100%;

        // Second level
        &>div {
            height: 100%;

            // Third level
            &>div {
                height: 100%;

                &>div {
                    height: 100%;

                    &>div {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.lottieContainer {
    position: absolute;
    inset: 0;
    z-index: 100;
    width: 100%;
    border-radius: $DefaultBorderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
}


.lottie {
    width: 250px;
    height: 250px;
}