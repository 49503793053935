@use '../../../../styles/variables' as *;

.knowledgeMap {
    width: 100%;
    padding: $sm $normal;
    display: flex;
    flex-direction: column;
    gap: $sm;
}

.rangeText {
    font-weight: bold;
    font-size: 1.1rem;
    text-align: center;
}

.container {
    width: 100%;
    flex: 1; // Take remaining space after rangeText
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $sm;
    min-height: 0; //
}

.gridWrapper {
    width: calc(100% - 64px);
    height: 100%;
    overflow: hidden; // Prevent any overflow
    display: flex; // Add this
    align-items: center; // Add this
    justify-content: center; // Add this
}

.grid {
    width: 100%;
    max-height: 100%; // Add this
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 2px;
    aspect-ratio: 25/4;
}

.square {
    width: 100%;
    aspect-ratio: 1/1;
    min-width: 0; // Add this
    min-height: 0; // Add this
    border-radius: 2px;
    background-color: #E5E7EB;
    position: relative; // For absolute positioning of inner square


    &.known {
        background-color: $Turquoise;
    }

    &.shouldLearn {
        background-color: $Violet;
    }

    &.current {
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40%; // Size of inner square
            height: 40%; // Size of inner square
            background-color: $Eggplant;
            border-radius: 1px;
        }
    }
}

@media (min-width: 600px) {
    .knowledgeMap {
        padding: $sm $normal;
        gap: $sm;
    }
}