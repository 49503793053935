@use 'src/styles/variables' as *;

.popUp {
    padding: 0 $normal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $normal;

    h2,
    p,
    button {
        margin: 0;
    }


}

.message {
    text-align: left;
}

.buttonContainer {
    width: 100%;
    display: flex;
    gap: $normal;
}