@import 'src//styles/variables.scss';


.addToListModal {
    padding: 0 $normal;
    display: flex;
    flex-direction: column;
    gap: $normal;
}


.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $normal;


    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    h1 {
        font-weight: bold;
        margin: 0;
    }
}

.modalBody {
    max-height: 150px;
    overflow-y: auto;
}

.checkboxRow {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    input[type="checkbox"] {
        cursor: pointer;
        width: 20px;
        height: 20px;
        accent-color: $Turquoise;
        border-radius: 3px;
        appearance: none;
        -webkit-appearance: none;
        border: 2px solid $Turquoise;
        position: relative;
        outline: none;
        background-color: white;

        &:checked {
            background-color: white;

            &::after {
                content: '';
                position: absolute;
                left: 4px;
                top: 1px;
                width: 6px;
                height: 11px;
                border: solid $Turquoise;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);

            }
        }
    }

    label {
        cursor: pointer;
    }

    .listTitle {
        font-size: 1rem;
    }
}

.modalFooter {
    display: flex;

    justify-content: space-between;
    align-items: center;
    gap: $lg;

    * {
        width: 50%;
    }

    button {
        margin: 0;
    }

    .newList {
        font-weight: bold;
        cursor: pointer;
    }

}