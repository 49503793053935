@use '../../../../styles//variables.scss' as *;

.wordListPageContent {
    width: 100%;
    flex: 1;
    padding: 0 $lg $normal;
}

.searchContainer {
    margin-bottom: $normal;
}



.wordList {
    display: flex;
    flex-direction: column;
    gap: $normal;
}