@use '../../../styles/variables' as *;

.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    max-width: $max-width;
    padding: $normal $normal $sm;
    position: fixed;
    bottom: 0;
    z-index: 101;
    background-color: white;
    color: black;
    transition: all 0.1s ease-in-out;
    border-top: 1px solid $Light2;
}

.menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: $sm;
    text-decoration: none;

    span {
        color: $Eggplant;
        font-size: 10px;
    }

    img {
        width: 24px;
    }
}