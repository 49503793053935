@import 'src//styles/variables.scss';


.createNewListModal {

    display: flex;
    flex-direction: column;
    gap: $normal;
}


.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;


    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    h1 {
        font-size: 1.3rem;
        margin: 0;
    }
}

.modalBody {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.label {
    font-size: 1.2rem;
    font-weight: bold;
}