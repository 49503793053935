@use '../../../../styles/variables.scss' as *;

.progressContent {
    display: flex;
    flex-direction: column;
    gap: $lg;
}

.periodSelectorContainer {
    display: flex;
    justify-content: space-between;
}

.periodSelector {
    display: flex;
    flex-direction: row;
    gap: $sm;


}

.goalText {
    cursor: pointer;
    background-color: $Light2;
    padding: $sm;
    border-radius: $SmBorderRadius;
    font-size: 12px;
    font-weight: 600;

    &.active {
        background-color: $Violet;
        color: white;
    }

}

@media (min-width: 480px) {
    .goalText {
        padding: $sm $normal;
    }
}