@use 'src/styles/variables' as *;

.LessonWizard {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    height: 100%;
}



.wizardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $normal $lg $normal;
    position: relative;
    overflow: hidden;
}