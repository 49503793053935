@use '/src/styles/variables.scss' as *;

.imageSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.wordRank {
    position: absolute;
    top: 0px;
    background-color: $Violet;
    padding: 2px 9px;
    border-radius: 0 0 $sm $sm;
    font-size: 12px;
    color: $White;
}

.moreOptions {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: black;
    background-color: $Light2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    aspect-ratio: 1/1;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.close {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: black;
    background-color: $Light2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    aspect-ratio: 1/1;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 16px;
        height: 16px;
    }
}