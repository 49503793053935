@use '../../styles/variables' as *;
@use '../../styles/animation' as *;

.discover {
    display: flex;
    flex-direction: column;
    gap: $normal;
    padding: $normal;
    animation: slideLeft 0.3s ease-out;

    h2 {
        align-self: flex-start;
        margin: 0;
    }

    button {
        margin: 0;
    }
}