@import 'src//styles/variables.scss';

.modalContent {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: $sm;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 $sm;
}

.imageSection {
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
        text-transform: capitalize;
    }

    span {
        color: $Turquoise;
    }
}

.translate {
    margin: 0;
    color: $Violet;

}

.meaning {
    margin: 0;
    text-align: left;
}

.buttonSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: $sm;

    button {
        margin-bottom: 0;
    }
}