@use '/src/styles/variables.scss' as *;

.friendsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $sm $normal $sm $lg;
    background-color: white;
    cursor: pointer;

    &.you {
        background-color: $Light2;
    }
}

.avatar {
    display: flex;
    align-items: center;
    gap: $normal;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid $Light2;
    }
}

.avatarIndex {
    font-weight: bold;
    font-size: 1rem;
    width: 20px;

    &.hidden {
        visibility: hidden;
    }
}

.name {
    font-size: 1.1rem;

}

.progress {
    display: flex;
    align-items: center;
    gap: $normal;

    img {
        width: 30px;
        height: 30px;
    }
}

.progressTime {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progressTimeValue {
    font-weight: bold;
}