@use '/src/styles/variables.scss' as *;

.quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: $lg;
    gap: $normal;
}

.quoteImage {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    // aspect-ratio: 1/1; // Ensures perfect circle
    // min-width: 100px; // Prevents shrinking
    // min-height: 100px; // Prevents shrinking
    // flex-shrink: 0; // Prevents flex container from squishing it
}

.quoteAuthor {
    margin: 0;
    font-weight: bold;
}


.quoteTitle {
    margin: 0;
    color: $Light5;
}

.quoteDescription {
    margin: 0;
}